'use strict';

const $salutationSelect = $('.js-salutation-select');

/**
 * Inits listeners for forms
 */
function initListeners() {
    // Preventing selecting the default option for salutation selects
    if ($salutationSelect.length > 0) {
        $salutationSelect.on('change', (event) => {
            const $currentSelect = $(event.target);

            if ($currentSelect.val()) {
                $currentSelect.find('.js-default-option').prop('disabled', true);
            }
        });
    }
}

/**
 * Entry point to init form scripts
 */
function init() {
    initListeners();
}

module.exports = {
    init: init
};
