'use strict';

const waterColor = '#dadada';
const landColor = '#f9f9f9';
const administrativeColor = '#767676';
const poiColor = '#E42313';
const roadColor = '#ececec';

const mapStyles = [
    {
        featureType: 'administrative',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'simplified'
            },
            {
                color: administrativeColor
            }
        ]
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#bdbdbd'
            }
        ]
    },
    {
        featureType: 'administrative.locality',
        elementType: 'labels.text',
        stylers: [
            {
                saturation: -20
            },
            {
                lightness: -5
            }
        ]
    },
    {
        featureType: 'administrative.locality',
        elementType: 'labels.text.fill',
        stylers: [
            {
                saturation: -5
            },
            {
                lightness: 20
            }
        ]
    },
    {
        featureType: 'administrative.neighborhood',
        elementType: 'labels.text',
        stylers: [
            {
                saturation: 5
            },
            {
                lightness: 40
            }
        ]
    },
    {
        featureType: 'administrative.province',
        elementType: 'labels.text.fill',
        stylers: [
            {
                saturation: 5
            },
            {
                lightness: 45
            }
        ]
    },
    {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on'
            },
            {
                color: landColor
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'off'
            },
            {
                color: poiColor
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
            {
                color: roadColor
            },
            {
                weight: 1
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },

    {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
            {
                color: roadColor
            },
            {
                weight: '1'
            }
        ]
    },

    {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: roadColor
            },
            {
                weight: '1'
            }
        ]
    },

    {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on'
            },
            {
                color: waterColor
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#9e9e9e'
            }
        ]
    }
];

module.exports = {
    mapStyles
};
